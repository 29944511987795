function trackFileDownload(event) {
    // event.preventDefault();
    if (typeof gtag === 'function') {
        gtag('event', 'custom_download', {
            'title': event.target.dataset.gaDownloadTitle,
            'label': event.target.dataset.gaDownloadLabel,
            // 'event_callback': () => {
            //     console.log('hi')
            // }
        })
    }
}

$(document).ready(() => {
    console.log('hello world')
    $('.ga-file-download').on('click', trackFileDownload)
})
